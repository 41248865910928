import React, { Component } from "react";
import PropTypes from "prop-types";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";

import data from "./apartment.json"

const theme = {
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#EF6C00",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#EF6C00",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

class Villa extends Component {
  render() {
    return (<div><strong>Yash Villa by Four Petals</strong><br/><br/><div>Near Nasarapur Village in Karjat, This is a 4BHK Private Villa in 1.5 Acre land with King Size Pool. Located 25 mins from Karjat Railway station</div><br/><div>We have WIFI, Table Tennis, Carrom Board, Chess, Board Games, Cricket and Badminton, Music System with Mic</div></div>)
  }
}

Villa.propTypes = {
  steps: PropTypes.object,
};

Villa.defaultProps = {
  steps: undefined,
};

class Apartment extends Component {

  componentWillMount() {
    const { location, type, building  } = this.props.steps;
    const apart = data.filter((item)=>{
      return item.location === location && item.type === type && item.building === building
    })[0]
    this.setState({ apart });
  }
  render() {
    return (<div><strong>Yash Villa by Four Petals</strong><br/><br/><div>Near Nasarapur Village in Karjat, This is a 4BHK Private Villa in 1.5 Acre land with King Size Pool. Located 25 mins from Karjat Railway station</div><br/><div>We have WIFI, Table Tennis, Carrom Board, Chess, Board Games, Cricket and Badminton</div></div>)
  }
}

Apartment.propTypes = {
  steps: PropTypes.object,
};

Apartment.defaultProps = {
  steps: undefined,
};

class ChatLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentWillMount() {
    const { linkURL, label } = this.props;

    this.setState({ linkURL, label });
  }

  render() {
    const { linkURL, label } = this.state;
    return (
      <div style={{ width: "100%" }}>
        {label} <a href={linkURL} rel="noreferrer" target="_blank">here</a>
      </div>
    );
  }
}

ChatLink.propTypes = {
  steps: PropTypes.object,
};

ChatLink.defaultProps = {
  steps: undefined,
};

const addProperty = function(key, triggerKey, label, capacity, rate, noOfGuest, photos, youtube, moreDetail) {
  return  {
    id: key,
    component: <div><div><strong>Apartment Type</strong>: {label}</div><br/><div><strong>Max Capacity</strong>: {capacity}</div><div><strong>Per Night</strong>: {rate} for {noOfGuest} Guests + 12% GST</div><div><br/><div><div><strong>Tariff Inclusion</strong><ul><li>WIFI</li><li>Andriod TV*</li><li>Housekeeping (Mopping, Dusting, Utensil Cleaning)</li><li>Water, Gas, Electricity Bill</li></ul></div></div><br/><div><strong>Check-in</strong>: 1 PM</div><div><strong>Checkout</strong>: 11 AM</div><div><strong>Prohibited</strong> : Visitors, Smoking, Party</div></div><br/><ChatLink linkURL={photos} label='Property Photos: ' /><ChatLink linkURL={youtube} label='Property Videos: ' /><ChatLink linkURL={moreDetail} label='More Detail: ' /><div><br/><strong>For Booking please call / WhatsApp us at +917005560002 </strong></div></div>,
    asMessage: true,
    trigger: triggerKey,
    delay: 6250
  }
}

let chatSteps = [
  {
    id: "0",
    message: "Welcome to Four Petals, Where you feel @ Home",
    trigger: "1",
  },
  {
    id: "1",
    message:
      "Feel free for call us at +917005560002 or Email us at care@fourpetals.in",
    trigger: "2",
    delay: 4250
  },
  {
    id: "2",
    message:
      "We will be happy to answer your queries here, Let us know your stay type",
    trigger: "stateType",
    delay: 5250
  },
  {
    id: "stateType",
    options: [
      { value: "sa", label: "Service Apartment", trigger: "sa1" },
      { value: "vi", label: "Villa", trigger: "vi1" },
      { value: "co", label: "Coliving / PG", trigger: "co1" },
    ],
  },
  {
      "id": "sa1",
      "message": "Great, We will be Happy to Host you at our properties located at Navi Mumbai, India",
      "trigger": "sa2"
    },
    {
      "id": "sa2",
      "message": "Lets us know your preferred Location",
      "trigger": "sa3"
    },
    {
      "id": "sa3",
      "options": [
        {
          "value": "cbd",
          "label": "CBD Belapur",
          "trigger": "sa1-cbd"
        },
        {
          "value": "san",
          "label": "Sanpada",
          "trigger": "sa1-san"
        },
        {
          "value": "va",
          "label": "Vashi",
          "trigger": "sa1-vas"
        },
        {
          "value": "ghan",
          "label": "Ghansoli",
          "trigger": "sa1-ghan"
        }
      ]
    },
    {
      "id": "sa1-cbd",
      "message": "In CBD Belapur we are available in 3 Locations",
      "trigger": "sa1-cbd-01"
    },
    {
      "id": "sa1-cbd-01",
      "options": [
        {
          "value": "sa1-cbd-01-sector20",
          "label": "Sector 20",
          "trigger": "sa1-cbd-20"
        },
        {
          "value": "sa1-cbd-01-sector11",
          "label": "Sector 11",
          "trigger": "sa1-cbd-01-sector11-01"
        }
      ]
    },
    {
      "id": "sa1-cbd-01-sector11-01",
      "message": "We are available in Tower II, next to KStar Hotel",
      "trigger": "sa1-cbd-01-sector11-02"
    },
    {
      "id": "sa1-cbd-01-sector11-02",
      "component":<div><div><strong>Address</strong>Tower 2, Sector 11, CBD Belapur, Navi Mumbai, Maharashtra 400614</div><br/><ChatLink linkURL='https://maps.app.goo.gl/TVvtQxsTwya2P3wUA' label='Google Map: ' /></div>,
       asMessage: true,
      "trigger": "sa1-cbd-01-sector11-03"
    },
    {
      "id": "sa1-cbd-01-sector11-03",
      "message": "Following options are available in Tower 2",
      "trigger": "sa1-cbd-01-sector11-04"
    },
    {
      "id": "sa1-cbd-01-sector11-04",
      "options": [
        {
          "value": "sa1-cbd-01-sector11-04-studio",
          "label": "Studio",
          "trigger": "sa1-cbd-01-sector11-04-studio-app"
        },
        {
          "value": "sa1-cbd-01-sector11-04-2bhk",
          "label": "2 BHK",
          "trigger": "sa1-cbd-01-sector11-04-2bhk-app"
        },
        {
          "value": "sa1-other-locations",
          "label": "Show other locations",
          "trigger": "sa3"
        },
      ]
    },
    {
      "id": "sa1-cbd-20",
      "message": "Please select building name for more details",
      "trigger": "sa1-san-20-01"
    },
    {
      "id": "sa1-san-20-01",
      "options": [
        {
          "value": "sa1-san-20-01-oberoi",
          "label": "Oberoi Apartment",
          "trigger": "sa1-san-20-01-oberoi-01"
        },
        {
          "value": "sa1-san-20-01-mohraj",
          "label": "Mohraj Apartment",
          "trigger": "sa1-san-20-01-mohraj-01"
        },
        {
          "value": "sa1-other-locations",
          "label": "Show other locations",
          "trigger": "sa3"
        },
      ]
    },
    {
      "id": "sa1-san-20-01-oberoi-01",
      "message": "2nd Lane behind Dmart, Oberoi apartment has following options",
      "trigger": "sa1-san-20-01-oberoi-01-add-01"
    },
    {
      "id": "sa1-san-20-01-oberoi-01-add-01",
      "component":<div><div><strong>Address</strong>:  Oberoi Apartment by Four Petals, Plot no B 375, Belapur Village, Sector 20, CBD Belapur, Navi Mumbai, Maharashtra 400614</div><br/><ChatLink linkURL='https://maps.app.goo.gl/TVvtQxsTwya2P3wUA' label='Google Map: ' /></div>,
       asMessage: true,
      "trigger": "sa1-san-20-01-oberoi-01-add-02"
    },
    {
      "id": "sa1-san-20-01-oberoi-01-add-02",
      "message": "Please select Apartment type",
      "trigger": "sa1-san-20-01-oberoi-02"
    },
    {
      "id": "sa1-san-20-01-oberoi-02",
      "options": [
        {
          "value": "sa1-san-20-01-oberoi-02-1bhk",
          "label": "1 BHK",
          "trigger": "sa1-san-20-01-oberoi-02-1bhk-app"
        },
        {
          "value": "sa1-san-20-01-oberoi-02-1bhk-terrace",
          "label": "1 BHK with  Terrace",
          "trigger": "sa1-san-20-01-oberoi-02-1bhk-terrace-app"
        },
        {
          "value": "sa1-san-20-01-oberoi-02-studio",
          "label": "Studio",
          "trigger": "sa1-san-20-01-oberoi-02-studio-app"
        },
        {
          "value": "sa1-other-locations",
          "label": "Show other locations",
          "trigger": "sa3"
        },
      ]
    },
    {
      "id": "sa1-san-20-01-mohraj-01",
      "message": "Right across busy lane of Sector 15, CBD Belapur. 2 minutes from Dmart. Opposite Croma",
      "trigger": "sa1-san-20-01-mohraj-01-add-01"
    },
    {
      "id": "sa1-san-20-01-mohraj-01-add-01",
      "component":<div><div><strong>Address</strong>:  Mohraj by Four Petals, Plot No 38, Belapur Village, Sector 20, CBD Belapur, Navi Mumbai, Maharashtra 400614</div><br/><ChatLink linkURL='https://maps.app.goo.gl/KXMYDTVQwDCKeyNX9' label='Google Map: ' /></div>,
       asMessage: true,
      "trigger": "sa1-san-20-01-mohraj-01-add-02"
    },
    {
      "id": "sa1-san-20-01-mohraj-01-add-02",
      "message": "Please select Apartment type",
      "trigger": "sa1-san-20-01-mohraj-02"
    },
    {
      "id": "sa1-san-20-01-mohraj-02",
      "options": [
        {
          "value": "sa1-san-20-01-mohraj-02-1bhk",
          "label": "2 BHK",
          "trigger": "sa1-san-20-01-mohraj-02-1bhk-app"
        },
        {
          "value": "sa1-san-20-01-mohraj-studio",
          "label": "Studio on Ground Floor",
          "trigger": "sa1-san-20-01-mohraj-studio-app"
        },
        {
          "value": "sa1-other-locations",
          "label": "Show other locations",
          "trigger": "sa3"
        },
      ]
    },
    {
      "id": "sa1-san",
      "message": "In Sanpada we are available at Sector 10, Near Juinagar Station. 2 mins walk to Dmart",
      "trigger": "sa1-san-add"
    },
    {
      "id": "sa1-san-add",
      "component":<div><div><strong>Address</strong>: Madhuleela by Four Petals, 122, Sector 10, Sanpada, Navi Mumbai, Maharashtra 400705</div><br/><ChatLink linkURL='https://maps.app.goo.gl/iVaRnxWPpn8QDYRk7' label='Google Map: ' /></div>,
       asMessage: true,
      "trigger": "sa1-san-01"
    },
    {
      "id": "sa1-san-01",
      "message": "We have the following options in Sanpada",
      "trigger": "sa1-san-02"
    },
    {
      "id": "sa1-san-02",
      "options": [
        {
          "value": "sa1-san-02-1.5",
          "label": "1.5 BHK",
          "trigger": "sa1-san-02-1.5-app"
        },
        {
          "value": "sa1-san-02-2",
          "label": "2BHK",
          "trigger": "sa1-san-02-2-app"
        },
        {
          "value": "sa1-san-02-studio",
          "label": "Studio",
          "trigger": "sa1-san-02-studio-app"
        },
        {
          "value": "sa1-san-02-1bhk",
          "label": "1 BHK",
          "trigger": "sa1-san-02-1bhk-app"
        },
        {
          "value": "sa1-other-locations",
          "label": "Show other locations",
          "trigger": "sa3"
        },
      ]
    },
    {
      "id": "sa1-vas",
      "message": "In Vashi we are available at Sector 26",
      "trigger": "sa1-vas-add"
    },
    {
      "id": "sa1-vas-add",
      "component":<div><div><strong>Address</strong>: Shankar Encalve by Four Petals, Plot 25, Kopri Rd, opposite Baba Cottage, Sector 26, Vashi, Navi Mumbai, Maharashtra 400703</div><br/><ChatLink linkURL='https://maps.app.goo.gl/4mcR2mAvMfTRQf8FA' label='Google Map: ' /></div>,
       asMessage: true,
      "trigger": "sa1-vas-01"
    },
    {
      "id": "sa1-vas-01",
      "message": "In Vashi all our apartments are 1BHK",
      "trigger": "sa1-vas-01-apartments"
    },
    {
      "id": "sa1-vas-01-apartments",
      "options": [
        {
          "value": "sa1-vas-01-apartments-1bhk",
          "label": "1 BHK",
          "trigger": "sa1-vas-01-apartments-1bhk"
        },
        {
          "value": "sa1-other-locations",
          "label": "Show other locations",
          "trigger": "sa3"
        },
      ]
    },
   
    {
      "id": "sa1-ghan",
      "message": "In Ghansoli we are available at Sector 30",
      "trigger": "sa1-ghan-add"
    },
    {
      "id": "sa1-ghan-add",
      "component":<div><div><strong>Address</strong>: Mohraj heights by Four Petals, Mari Aai Heights, opposite Mari Aai Temple, Gothivali Village, Sector 30, Ghansoli, Navi Mumbai, Maharashtra 400701</div><br/><ChatLink linkURL='https://maps.app.goo.gl/MnEXsemSMMKsk8qz8' label='Google Map: ' /></div>,
       asMessage: true,
      "trigger": "sa1-ghan-01"
    },
    {
      "id": "sa1-ghan-01",
      "message": "In Ghansoli we have the following options",
      "trigger": "sa1-ghan-02"
    },
    {
      "id": "sa1-ghan-02",
      "options": [
        {
          "value": "sa1-ghan-02-1BHK",
          "label": "1BHK",
          "trigger": "sa1-ghan-02-1BHK-app"
        },
        {
          "value": "sa1-ghan-02-studio",
          "label": "Studio",
          "trigger": "sa1-ghan-02-studio-app"
        },
        {
          "value": "sa1-other-locations",
          "label": "Show other locations",
          "trigger": "sa3"
        },
      ]
    },
    {
      id: "vi1",
      message: "Yash Villa by Four Petals, Karjat",
      trigger: "vi2",
      delay: 1000
    },
    {
      id: "vi2",
      component : <Villa/>,
      asMessage: true,
      trigger: "vi3-1",
      delay: 3250
    },
    {
      id: "vi3-1",
      component:<div><div><strong>Check-in</strong> 1 PM</div><div><strong>Checkout</strong> 11 AM</div><br/><ChatLink linkURL='https://photos.app.goo.gl/KoVo7rmoXapM3W8u7' label='Property Photos: ' /><ChatLink linkURL='https://www.youtube.com/watch?v=XKoUg0kfe40&t=1s' label='Property Videos: ' /><ChatLink linkURL='https://maps.app.goo.gl/4UX6chRhpUzV4fJfA' label='Google Map: ' /><ChatLink linkURL='https://www.fourpetals.in/img/food-menu.jpg' label='Food Menu: ' /></div>,
      asMessage: true,
      trigger: "vi3-4",
      delay: 4250
    },
    {
      id: "vi3-4",
      message: "For booking 50% payment needs to be made, balance 50% needs to be made at the time of Checkin. Additional 5K deposit needs to given at the time of checkin. Same will be refunded post checkout",
      trigger: "vi3-5",
      delay: 4250
    },
    {
      id: "vi3-5",
      message: "A Rs. 5000 Security deposit needs to be paid the time of checkin, same will be refunded post checkout.",
      trigger: "vi3-6",
      delay: 5250
    },
    {
      id: "vi3-5",
      message: "Any damage to property will be deducted from Security Deposit",
      trigger: "vi4",
    },
    {
      id: "vi4",
      message: "Please select when do you wish to stay with us",
      trigger: "vi5",
    },
    {
      id: "vi5",
      options: [
        { value: "weekday", label: "Weekday (Non Holiday)", trigger: "weekday" },
        { value: "weekend", label: "Weekend", trigger: "weekend" },
      ],
    },
    {
      id: "weekend",
      options: [
        { value: "oddWeekend", label: "Friday / Sunday", trigger: "oddWeekend" },
        { value: "saturday", label: "Saturday", trigger: "saturday" },
      ],
    },
    {
      id: "saturday",
      message: "Please select Guest Count",
      trigger: 'saturday',
    },
    {
      id: "saturday",
      options: [
        { value: "moreThan20", label: "More than 20", trigger: "moreThan20" },
        { value: "moreThan12", label: "More than 12", trigger: "moreThan12" },
        { value: "lessThan12", label: "less than 12", trigger: "lessThan12" },
      ],
    },
    {
      id: "lessThan12",
      message: "Rs 36000 including all Meals (Lunch, High Tea, Dinner & Breakast), Private Villa",
      trigger: 'otherOptions'
    },
    {
      id: "moreThan12",
      message: "Rs 3250 Per head including all Meals (Lunch, High Tea, Dinner & Breakast), Private Villa",
      trigger: 'otherOptions'
    },
    {
      id: "moreThan20",
      message: "Rs 2850 Per head including all Meals (Lunch, High Tea, Dinner & Breakast), Private Villa",
      trigger: 'otherOptions'
    },
    {
      id: "weekday",
      message: "Please select Guest Count",
      trigger: 'weekdayGuestCount',
    },
    {
      id: "weekdayGuestCount",
      options: [
        { value: "lessThan10", label: "Less than 10", trigger: "lessThan10" },
        { value: "moreThan10", label: "More than 10", trigger: "moreThan10" },
      ],
    },
    {
      id: "lessThan10",
      message: "Rs 10000 for Stay and Rs.1100 per head for Food including all Meals (Lunch, High tea, dinner and Breakfast). Food is Optional. Private Villa",
      trigger: 'otherOptions'
    },
    {
      id: "moreThan10",
      message: "Rs. 1000 for stay and Rs. 1100 for Food including all Meals (Lunch, High tea, dinner and Breakfast). Food is Optional. Private Villa",
      trigger: 'otherOptions'
    },
    {
      id: "oddWeekend",
      message: "Per Head Rs. 2850 including all Meals (Lunch, High tea, dinner and Breakfast), Minimum 8 Guest. Private Villa",
      trigger: 'otherOptions'
    },
    {
      id: "otherOptions",
      message: "Want to try other combinations?",
      trigger: 'combinations',
    },
    {
      id: "combinations",
      options: [
        { value: "yes", label: "Yes", trigger: "vi4" },
        { value: "no", label: "No", trigger: 'contact'},
      ],
    },
    {
      id: "contact",
      message: "For any queries you can Call or WhatsApp us at +917005560002. Do you wants to us to Call you?",
      trigger: "callUs"
    },
    {
      id: "callUs",
      options: [
        { value: "yes", label: "Yes", trigger: "mobileMessage" },
        { value: "no", label: "No", trigger: 'stateType'},
      ],
    },
    {
      id:"mobileMessage",
      message: "Please enter your mobile number",
      delay: 1750,
      trigger: "mobile",
    },
    {
      id: "mobile",
      user: true,
      trigger : "callBack",
      validator: (value) => {
        if (isNaN(value)) {
          return "Please enter valid Mobile Number";
        } 

        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
          if (this.readyState === 4 && this.status === 200) {
             console.log("Send")
          }
      };
      xhttp.open("GET", `https://app.fourpetals.in/api/external/ivr?uniqueid='CHAT-${Math.random()}'&from=${value}&agent_number=&extension=4&status=CANCELLED&time=${new Date(Date.now()).toLocaleString()}total_duration=0`, true);
      xhttp.send();
        return true;
      }
    },
    {
      id: "callBack",
      message: "Thanks we will call back on {previousValue} shortly. Feel free to check our website or you can directly call us on +91700550002",
      trigger: "stateType"
    },
    {
      id: "co1",
      message : "Located at 5 mins walk from Rabale railway stations",
      trigger : "co1-add",
      delay : 2000
    },
    {
      id: "co1-add",
      "component":<div><div><strong>Address</strong>: Conest Living by Four Petals, Mari Aai Heights, opposite Mari Aai Temple, Gothivali Village, Sector 30, Ghansoli, Navi Mumbai, Maharashtra 400701</div><br/><ChatLink linkURL='https://maps.app.goo.gl/88yn4dPkKahbnaXJ6' label='Google Map: ' /><ChatLink linkURL='https://www.youtube.com/shorts/vCYSsKM2-bA' label='Video: ' /></div>,
      asMessage: true,
      trigger : "co1-details",
      delay : 2000
    },
    { 
      id:"co1-details",
      message : "We provide 2 options, Twin Sharing (double bed) AC & Four Sharing Non AC Options",
      delay : 2500,
      trigger : "co1-pricing",
    },
    { 
      id:"co1-pricing",
      message : "Twin Sharing Options starts from Rs. 16000 + taxes per month per bed AC, Four Sharing starts from Rs. 12000 + taxes per bed NON AC",
      delay : 2500,
      trigger : "co1-inclusion"
    },
    {
      id: "co1-inclusion",
      "component":<div><ul><strong>Amenities</strong><li>Wifi</li><li>Alternate day Cleaning</li><li>Private Wadrobe</li><li>Private Study Desk</li><li>Water Purifier</li><li>Water Heater</li><li>Refrigerator*</li><li>Microwave*</li></ul><br/><ChatLink linkURL='https://photos.app.goo.gl/xLtAiZTsjKrsY2L67' label='Photos: ' /></div>,
      asMessage: true,
      trigger : "contact",
      delay : 2000
    },
  {
    id: "name",
    user: true,
    trigger: "3",
  },
  {
    id: "3",
    message: "Hi {previousValue}! What is your gender?",
    trigger: "gender",
  },
  {
    id: "gender",
    options: [
      { value: "male", label: "Male", trigger: "5" },
      { value: "female", label: "Female", trigger: "5" },
    ],
  },
  {
    id: "5",
    message: "How old are you?",
    trigger: "age",
  },
  {
    id: "age",
    user: true,
    trigger: "7",
    validator: (value) => {
      if (isNaN(value)) {
        return "value must be a number";
      } else if (value < 0) {
        return "value must be positive";
      } else if (value > 120) {
        return `${value}? Come on!`;
      }

      return true;
    },
  },
  {
    id: "7",
    message: "Great! Check out your summary",
    trigger: "review",
  },
  {
    id: "review",
    component: <ChatLink />,
    asMessage: true,
    trigger: "update",
  },
  {
    id: "update",
    message: "Would you like to update some field?",
    trigger: "update-question",
  },
  {
    id: "update-question",
    options: [
      { value: "yes", label: "Yes", trigger: "update-yes" },
      { value: "no", label: "No", trigger: "end-message" },
    ],
  },
  {
    id: "update-yes",
    message: "What field would you like to update?",
    trigger: "update-fields",
  },
  {
    id: "update-fields",
    options: [
      { value: "name", label: "Name", trigger: "update-name" },
      { value: "gender", label: "Gender", trigger: "update-gender" },
      { value: "age", label: "Age", trigger: "update-age" },
    ],
  },
  {
    id: "update-name",
    update: "name",
    trigger: "7",
  },
  {
    id: "update-gender",
    update: "gender",
    trigger: "7",
  },
  {
    id: "update-age",
    update: "age",
    trigger: "7",
  },
  {
    id: "end-message",
    message: "Thanks! Your data was submitted successfully!",
    end: true,
  },
]


chatSteps.push(addProperty("sa1-vas-01-apartments-1bhk", "sa1-vas-01-apartments", "1 Bedroom Hall Kitchen", 4, 3750, 2, 'https://photos.app.goo.gl/Dzd7M9Uq34FnCKwU6', 'https://youtu.be/aP7b0_qYqJk?si=y3ZGP1arQSEpVbm3&t=4m59s', 'https://www.fourpetals.in/Shankar.html' ))
chatSteps.push(addProperty("sa1-san-02-1.5-app", "sa1-san-02", "1.5 Bedroom Hall Kitchen", 3, 4100, 2, 'https://photos.app.goo.gl/cM3M7MFyV2Nj7CSL9', '', 'https://www.fourpetals.in/Madhuleela.html' ))
chatSteps.push(addProperty("sa1-san-02-2-app", "sa1-san-02", "2 Bedroom Hall Kitchen", 3, 5250, 2, 'https://photos.app.goo.gl/27WDmUS9zViqMwcL7', '', 'https://www.fourpetals.in/Madhuleela.html' ))
chatSteps.push(addProperty("sa1-san-02-studio-app", "sa1-san-02", "1 Room Kitchen", 2, 3250, 2, 'https://photos.app.goo.gl/erh8d8q3khwCsKrs5', '', 'https://www.fourpetals.in/Madhuleela.html' ))
chatSteps.push(addProperty("sa1-san-02-1bhk-app", "sa1-san-02", "1 BedRoom Hall Kitchen", 2, 3450, 2, 'https://photos.app.goo.gl/dcgqz3pqgyB2Gznw9', '', 'https://www.fourpetals.in/Madhuleela.html' ))
chatSteps.push(addProperty("sa1-san-20-01-mohraj-02-1bhk-app", "sa1-san-20-01-mohraj-02", "2 BedRoom Hall Kitchen", 5, 5500, 4, 'https://photos.app.goo.gl/uRQ6CRV7HAoDkqm2A', '', 'https://www.fourpetals.in/Mohraj.html' ))
chatSteps.push(addProperty("sa1-san-20-01-mohraj-studio-app", "sa1-san-20-01-mohraj-02", "1 Room Kitchen", 2, 2699, 2, 'https://photos.app.goo.gl/ShAPsbUJBfXbMAvg6', '', 'https://www.fourpetals.in/Mohraj.html' ))
chatSteps.push(addProperty("sa1-san-20-01-oberoi-02-studio-app", "sa1-san-20-01-oberoi-02", "1 Room Kitchen", 2, 3350, 2, 'https://photos.app.goo.gl/DymKK8mLmX7DAgV97', 'https://youtu.be/aP7b0_qYqJk?si=y3ZGP1arQSEpVbm3', 'https://www.fourpetals.in/Oberoi.html' ))
chatSteps.push(addProperty("sa1-san-20-01-oberoi-02-1bhk-app", "sa1-san-20-01-oberoi-02", "1 Bed Room Hall Kitchen", 3, 3750, 2, 'https://photos.app.goo.gl/tW6AJuyGmMYw3xia6', 'https://youtu.be/aP7b0_qYqJk?si=y3ZGP1arQSEpVbm3', 'https://www.fourpetals.in/Oberoi.html' ))
chatSteps.push(addProperty("sa1-san-20-01-oberoi-02-1bhk-terrace-app", "sa1-san-20-01-oberoi-02", "1 Bed Room Hall Kitchen with terrace", 3, 4250, 2, 'https://photos.app.goo.gl/tW6AJuyGmMYw3xia6', 'https://youtu.be/aP7b0_qYqJk?si=y3ZGP1arQSEpVbm3', 'https://www.fourpetals.in/Oberoi.html' ))

chatSteps.push(addProperty("sa1-cbd-01-sector11-04-2bhk-app", "sa1-cbd-01-sector11-04", "2 BedRoom Hall Kitchen", 5, 5999, 4, 'https://photos.app.goo.gl/r9ZzkvkxXCcgeeGAA', '', 'https://www.fourpetals.in/Tower-II-Belapur.html' ))
chatSteps.push(addProperty("sa1-cbd-01-sector11-04-studio-app", "sa1-cbd-01-sector11-04", "Studio Apartment", 2, 3350, 2, 'https://photos.app.goo.gl/tg44pXFCdofiH64t6', '', 'https://www.fourpetals.in/Tower-II-Belapur.html' ))
chatSteps.push(addProperty("sa1-ghan-02-1BHK-app", "sa1-ghan-02", "1 BedRoom Hall Kitchen", 3, 3250, 2, 'https://photos.app.goo.gl/tmvnbvTpoZ1pdFYh8', '', 'https://www.fourpetals.in/Mohraj-heights.html' ))
chatSteps.push(addProperty("sa1-ghan-02-studio-app", "sa1-ghan-02", "1 Room Kitchen", 3, 2999, 2, 'https://photos.app.goo.gl/QZ6wrjf4XM9xBrYs7', '', 'https://www.fourpetals.in/Mohraj-heights.html' ))


class SimpleForm extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ChatBot 
          bubbleStyle={{'text-align':'left', 'max-width' : '65%'}}
          floating={true}

          botAvatar="./logo.ico"
          steps={chatSteps}
        />
      </ThemeProvider>
    );
  }
}

export default SimpleForm;
